<template>
    <input 
        class="dispAuth__input" 
        required 
        autocomplete 
        :type="type" 
        :id="inputId"
        v-model="val"
        @input="formInput()"
        :class="{ 'dispAuth__input--invalid' : !validInput }"
        :placeholder="label"
    >
</template>

<script>
export default {
    name: 'formInput',
    props: [
        'label',
        'inputId',
        'reg',
        'type'
    ],
    data() {
        return {
            val: '',
            validInput: true
        }
    },
    methods: {
        formInput: function() {
            let inputVal = this.val
            let pattern = /[^A-Za-zА-Яа-я0-9\\.,-_]/g
            this.validInput = false

            pattern.test(inputVal) ? this.validInput = false : this.validInput = true 
            
            this.$emit('validInput', {
                input: this.inputId,
                status: this.validInput,
                val: inputVal
            })
        }
    }
}
</script>