<template>
    <div class="dispAuth">

        <DispSpinner v-if="getSocketIsLoading" />

        <div v-else class="dispAuth__wrapper">
            <span class="dispAuth__title">Авторизация</span>

            <form class="dispAuth__form" @submit.prevent="sendAuth()">
                <div class="dispAuth__formGroup">
                    <DispAuthInput 
                        label="Логин" 
                        inputId="userLogin" 
                        type="text"
                        @validInput="validInput" 
                    />
                </div>

                <div class="dispAuth__formGroup">
                    <DispAuthInput 
                        label="Пароль" 
                        inputId="userPass" 
                        type="password"
                        @validInput="validInput" 
                    />
                </div>

                <div class="dispAuth__error" v-if="getUserMsg">
                    {{ getUserMsg }}
                </div>

                <div class="dispAuth__formGroup">
                    <input  
                        type="submit"
                        class="dispAuth__input dispAuth__input--submit"
                        value="Вход"
                        :class="{ 'dispAuth__input--ready' : validForm }"
                    >
                </div>
            </form>

        </div><!-- /.dispAuth__wrapper -->
    </div><!-- /.dispAuth -->
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import DispAuthInput from '@/components/DispAuthInput'
import DispSpinner from '@/components/DispSpinner'

import { Base64 } from 'js-base64'

let formFields = new Map()
formFields.set('userLogin', false)
formFields.set('userPass', false)

export default {
    name: 'Auth',
    components: {
        DispAuthInput,
        DispSpinner
    },
    data: () => ({
        validForm: false,
        user: false
    }),
    computed: {
        ...mapGetters([
            'getUserMsg', 
            'getSocket',
            'getSocketIsLoading',
            'authProcess'
        ])
    },
    methods: {
        ...mapMutations([
            'updateUserMsg',
            'setAuthProcess'
        ]),
        ...mapActions(['auth']),

        validInput (result) {
            if (result.status) {
                formFields.set(result.input, result.val);
            } else {
                formFields.set(result.input, false);
            }

            if (formFields.get('userLogin') && formFields.get('userPass')) {
                this.validForm = true;
            } else {
                this.validForm = false;
            }
        },

        sendAuth () {
            if (this.validForm) {
                this.updateUserMsg(null)

                const cmd = 'ul' + Base64.encode(JSON.stringify({
                    lo: formFields.get('userLogin'),
                    po: formFields.get('userPass')
                }))
                
                this.auth(cmd)

            } else {
                this.updateUserMsg('Форма заполнена некорректно')
            }
        }
    },
    watch: {
        authProcess (val) {
            if (val) {
                localStorage.setItem('DISP_L', formFields.get('userLogin'))
                localStorage.setItem('DISP_P', formFields.get('userPass'))
                this.setAuthProcess(false)
                this.$router.push('/')
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/views/auth';
</style>